import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Heading from 'src/components/heading/Heading'
import { Header } from 'src/components/layout'
import { openDeepLink } from 'src/utils'
import OpenApp from 'src/components/open-app'

import classNames from 'classnames'

// Styles & Images
import 'static/confirm/scss/styles.scss'

// Partials:
const DefaultIcon = ({ icon, size }) => (
  <figure className="x__icon-container--animating">
    <img className={`x__icon--${size}`} src={icon} />
  </figure>
)

const DefaultHeadings = ({ page, section, subHeadingProps }) => (
  <div className="x__headings-container--animating">
    <div className="x__heading">
      <Heading page={page} section={section} />
    </div>
    <div className="x__subheading">
      <Heading type="h2" page={page} section={section} {...subHeadingProps} />
    </div>
  </div>
)

const REDIRECT_TIMEOUT = 4500

// Main component:
const ConfirmPage = ({
  icon,
  iconSize,
  fullLogo,
  backgroundGradient,
  page,
  deepLinkPath,
  redirectUrl,
  mobileAppLink,
  location,
  section,
  subHeadingProps,
}) => {
  const [animate, setAnimate] = useState(false)

  /* get state if URL has params */
  const success = location.search && new URL(location.href).searchParams.get('success')
  const error = !!success && parseInt(success) !== 1

  useEffect(() => setTimeout(setAnimate(true), 1), [])

  useEffect(() => {
    if (deepLinkPath) {
      setTimeout(() => openDeepLink(deepLinkPath), REDIRECT_TIMEOUT)
    } else if (redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl
      }, REDIRECT_TIMEOUT)
    }
  }, [deepLinkPath, redirectUrl])

  return (
    <div
      className={classNames('x__page-confirm', {
        'x__page-confirm--animating': animate,
        'x__page-confirm--background-gradient': backgroundGradient,
      })}
    >
      <Header
        minimal
        fullLogo={fullLogo}
        background={backgroundGradient ? 'transparent' : 'default'}
      />

      {mobileAppLink ? <OpenApp link={mobileAppLink} /> : null}

      <main className="x">
        <DefaultIcon icon={icon} size={iconSize} />
        <DefaultHeadings
          page={page}
          section={section || (error ? 'error' : 'success')}
          subHeadingProps={subHeadingProps}
        />
      </main>
    </div>
  )
}

// Main component props:
ConfirmPage.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.oneOf(['normal', 'larger', 'largest']),
  fullLogo: PropTypes.bool,
  backgroundGradient: PropTypes.bool,
  page: PropTypes.string,
  deepLinkPath: PropTypes.string,
  location: PropTypes.object,
}

ConfirmPage.defaultProps = {}

export default ConfirmPage
